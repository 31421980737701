import React, { useEffect, useState } from "react";
import "./style.scss";

import Profile from "../../assets/icons/profile.svg";
import BlueTick from "../../assets/icons/tick-blue.svg";
import DollarGreen from "../../assets/icons/dollar-green.svg";
import Edit from "../../assets/icons/edit-pencil.svg";
import Cross from "../../assets/icons/circle-cross.svg";
import DollarBlack from "../../assets/icons/dollar-black-square.svg";
import Standard from "../../assets/icons/standard.svg";
import FreeTrial from "../../assets/icons/free-trial.svg";
import Competition from "../../assets/icons/trophy.svg";
import InfoBox from "../../reusableComponents/InfoBox";
import ArrowIncrease from "../../assets/icons/arrow-increase.svg";
import ArrowDecrease from "../../assets/icons/arrow-increase.svg";
import calenderLight from "../../assets/icons/calenderLight.svg";
import tradeLight from "../../assets/icons/tradeLight.svg";
import levarageLight from "../../assets/icons/levrageLight.svg";
import expiryLight from "../../assets/icons/expiryLight.svg";
import moment from "moment";
import greenTick from "../../assets/icons/greenTick.svg";
import crossTick from "../../assets/icons/crossTick.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setActiveAccount } from "../../store/reducers/accountSlice";

const ProfileDropdown = ({ profileDropdown, setProfileDropdown }) => {
  const accountList = useSelector((state) => state.account.accountList);
  const [login_id, setLogin_id] = useState(accountList.data);
  const [active, setActive] = useState("All");

  const navigate = useNavigate();

  const loginFilter = (e) => {
    if (e === "All") {
      setLogin_id(accountList.data);
    } else {
      const filterData = accountList.data.filter(
        (ele, ind) => ele.challenge === e
      );
      setLogin_id(filterData);
    }
    setActive(e);
  };

  const dispatch = useDispatch();
  const active_id = useSelector((state) => state.account.accountList);

  const accountsData = useSelector((state) => state.account.accountMetrics);
  let infoBoxArr = [
    {
      title: "Account Balance",
      value: accountsData?.data?.balance_equity?.balance
        ? "$ " + accountsData?.data?.balance_equity?.balance
        : "na",
      icon: ArrowIncrease,
      percentage: "+2.53%",
      color: "#F75C03",
    },
    {
      title: "Equity",
      value: accountsData?.data?.balance_equity?.equity
        ? "$ " + accountsData?.data?.balance_equity?.equity
        : "na",
      icon: ArrowDecrease,
      percentage: "-1.2%",
      color: "#4CB944",
    },
  ];
  const [Ametrics, setAmetrics] = useState([]);
  useEffect(() => {
    setAmetrics(accountsData.data);
  }, [accountsData]);

  const userDetails = useSelector(
    (state) => state.account.accountList.activeUser
  );
  const uniqueChallenges = [
    ...new Set(accountList.data.map((account) => account.challenge)),
  ];

  return (
    <div
      className={`dropdown dropdown_profile`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="dropdown_upper_wrapper">
        <div className="dropdown_header">
          <p>Account Details</p>
          <img
            src={Cross}
            alt="cross icon"
            onClick={() => setProfileDropdown(!profileDropdown)}
          />
        </div>

        <div className="dropdown_profile_content">
          <div className="profile_details">
            <div className="profile_div">
              <div className="profile_img_wrapper">
                {/* <img src={Profile} alt="profile icon" /> */}
              </div>
              <div className="profile_details_content">
                <h4>{userDetails.name}</h4>
                <p>
                  {" "}
                  Active Account:<b> {active_id?.activeAccount.id}</b>
                </p>
              </div>
            </div>

            <div className="edit_img_wrapper">
              <div
                onClick={() =>
                  navigate("/traders-info/details", {
                    state: { data: userDetails },
                  })
                }
                // to="/traders-info/details"
              >
                <img
                  src={Edit}
                  alt="edit icon"
                  onClick={() => setProfileDropdown(false)}
                />
              </div>
            </div>
          </div>
          <div className="info_box_container abother_class">
            {infoBoxArr?.map((info, idx) => (
              <InfoBox
                key={info.title}
                title={info.title}
                value={info.value}
                icon={info.icon}
                // percentage={info.percentage}
                color={info.color}
                idx={idx}
              />
            ))}
          </div>
          <div className="accounts_type_wrapper">
            <ul className="account_type">
              <li>All Accounts</li>
              <li onClick={() => loginFilter("All")}>
                {active === "All" ? (
                  <img
                    src={greenTick}
                    className={active !== "All" ? "imgActive" : ""}
                    alt="aggressive account"
                  />
                ) : (
                  <img
                    src={crossTick}
                    className={active !== "All" ? "imgActive" : ""}
                    alt="aggressive account"
                  />
                )}
                All
              </li>
              {uniqueChallenges.map((challenge) => (
                <li onClick={() => loginFilter(challenge)}>
                  <img
                    className={active !== challenge ? "imgActive" : ""}
                    src={DollarGreen}
                    alt="aggressive account"
                  />
                  {challenge}
                  {active === challenge ? (
                    <img
                      className="blue_tick_icon"
                      src={BlueTick}
                      alt="tick icon"
                    />
                  ) : null}
                </li>
              ))}
              {/* <li onClick={() => loginFilter("Essential Challenge")}>
                {active === "Essential Challenge" ? (
                  <img
                    className="blue_tick_icon"
                    src={greenTick}
                    alt="tick icon"
                  />
                ) : (
                  <img
                    className="blue_tick_icon"
                    src={crossTick}
                    alt="tick icon"
                  />
                )}
                Essential Challenge
              </li>
              <li onClick={() => loginFilter("Ultra Pro Challenge")}>
                {active === "Ultra Pro Challenge" ? (
                  <img
                    className="blue_tick_icon"
                    src={greenTick}
                    alt="tick icon"
                  />
                ) : (
                  <img
                    className="blue_tick_icon"
                    src={crossTick}
                    alt="tick icon"
                  />
                )}
                Ultra Pro Challenge
              </li>
              <li onClick={() => loginFilter("Standard")}>
                {active === "Standard" ? (
                  <img
                    className="blue_tick_icon"
                    src={greenTick}
                    alt="tick icon"
                  />
                ) : (
                  <img
                    className="blue_tick_icon"
                    src={crossTick}
                    alt="tick icon"
                  />
                )}
                Standard
              </li>
              <li onClick={() => loginFilter("Free Trial")}>
                {active === "Free Trial" ? (
                  <img
                    className="blue_tick_icon"
                    src={greenTick}
                    alt="tick icon"
                  />
                ) : (
                  <img
                    className="blue_tick_icon"
                    src={crossTick}
                    alt="tick icon"
                  />
                )}
                Free Trial
              </li>
              <li onClick={() => loginFilter("Competitions")}>
                {active === "Competitions" ? (
                  <img
                    className="blue_tick_icon"
                    src={greenTick}
                    alt="tick icon"
                  />
                ) : (
                  <img
                    className="blue_tick_icon"
                    src={crossTick}
                    alt="tick icon"
                  />
                )}
                Competitions
              </li> */}
            </ul>
            <ul className="live_account_type">
              <li>Live Accounts</li>
              {login_id?.length <= 0
                ? "No data"
                : login_id?.map((ele, ind) => (
                    <li
                      key={ind}
                      onClick={() => {
                        dispatch(setActiveAccount(ele.login_id));
                      }}
                    >
                      {active_id?.activeAccount.id === ele.login_id ? (
                        <img
                          className="blue_tick_icon"
                          src={greenTick}
                          alt="tick icon"
                        />
                      ) : (
                        <img
                          className="blue_tick_icon"
                          src={crossTick}
                          alt="tick icon"
                        />
                      )}
                      {ele.login_id}
                    </li>
                  ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="dropdown_footer">
        <div className="first_box_1">
          <div className="mainDiv">
            <div>
              <img src={calenderLight} alt="" />
              <p className="title">Start Date</p>
            </div>

            <p className="value">{moment(Ametrics.start_date).format("ll")}</p>
          </div>
          <div className="mainDiv">
            <div>
              <img src={calenderLight} alt="" />
              <p className="title">Expiry Date</p>
            </div>
            <p className="value">{moment(Ametrics.expiry_date).format("ll")}</p>
          </div>
        </div>

        <div className="first_box_1 secound_box_2">
          <div className="mainDiv">
            <div>
              <img src={tradeLight} alt="" />
              <p className="title">No Of Trades</p>
            </div>
            <p className="value">
              {Ametrics?.account_advance_metrics?.total_trades || "NA"}
            </p>
          </div>
          <div className="mainDiv">
            <div>
              <img src={levarageLight} alt="" />
              <p className="title">Leverage</p>
            </div>
            <p className="value">
              {Ametrics?.account_metrics?.leverage || "NA"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropdown;
